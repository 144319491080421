<template>
    <div class="allowancebanner">
        <img src="https://sho-static.shulan.com/minipro/alading.jpg" class="banner" />
        <div class="reference">
            <div class="referenceLeft" @click="gotoa">
                <img src="../../assets/img/beian.png" />
                <div>浙公网安备 33010502007018号</div>
            </div>
            <div class="referenceRight">
                <div>中国·杭州 树兰医疗</div>
                <div>
                    Copyright 2017-2023 树兰医疗 版权所有·
                    <span @click="gotob">浙ICP备20016060号</span>
                </div>
                <div>
                    互联网药品信息服务资格证书编号：（浙）-经营性-2023-0093
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {};
    },
    methods: {
        gotoa() {
            window.open('https://www.beian.gov.cn/portal/registerSystemInfo?recordcode=33010502007018', '_self');
        },
        gotob() {
            window.open('https://beian.miit.gov.cn/#/Integrated/index', '_self');
        }
    }
};
</script>
<style lang="scss">
.allowancebanner {
    display: flex;
    flex-direction: column;
    .banner {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    .reference {
        cursor: pointer;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        padding: 30px 20%;
        background: rgba(60, 60, 60, 1);
        color: rgba(185, 190, 194, 1);
        font-size: 8px;
        .referenceLeft {
            display: flex;
            align-items: center;
        }
    }
}
</style>
